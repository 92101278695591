(function() {
	"use strict";

	angular
		.module("smartermail")
		.controller("attachDomainController", attachDomainController);

	function attachDomainController($rootScope, $scope, $http, $mdDialog, toaster, successHandling, $translate, $sanitize) {

		var vm = this;
		vm.os = window.stOS
		vm.osPathConversionNeeded = false;
		vm.domainArchivePathNew = undefined;
		vm.domainArchivePathOld = undefined;
		vm.errorMessage = undefined;

		// Variables
		$scope.domainPath = "";

		// Functions
		$scope.attach = attach;

		activate();

		////////////////////////

		function activate() {
		}

		$scope.cancel = function() {
			$mdDialog.cancel();
		};

		function attach(attachForm) {
			if (!attachForm.$valid) {
				attachForm.$submitted = true;
				return;
			}

			var params = undefined;
			if (vm.domainArchivePathNew === undefined) {
				params = JSON.stringify({
					domainPath: vm.os == 'L' ? $scope.domainPath.trimEnd("/") : $scope.domainPath.trimEnd("\\"),
				});
			} else {
				params = JSON.stringify({
					domainPath: vm.os == 'L' ? $scope.domainPath.trimEnd("/") : $scope.domainPath.trimEnd("\\"),
					domainArchivePathNew: vm.os == 'L' ? vm.domainArchivePathNew.trimEnd("/") : vm.domainArchivePathNew.trimEnd("\\"),
				});
			}
			
			$rootScope.spinner.show();
			$http
				.post("~/api/v1/settings/sysadmin/attach-domain", params)
				.then(function () {
					successHandling.report
					vm.errorMessage = undefined;
					vm.osPathConversionNeeded = false;
				}, errorReport)
				.finally(onComplete);

			function onComplete() {
				if (vm.errorMessage !== undefined || vm.osPathConversionNeeded === true) {
					$rootScope.spinner.hide();
				} else {
					$rootScope.spinner.hide();
					$mdDialog.hide();
				}
			}
		}
		
		function errorReport(failure) {
			if (failure && failure.data)
				failure = failure.data;
			if (failure && failure.message) {
				var msg = "";
				var time = 15000;
				if (failure.message === "DOMAIN_MANAGEMENT_ATTACH_DOMAIN_CONVERSION_REQUIRED") {
					msg = $translate.instant(failure.message);
					time = 0;
				} else if (failure.message === "DOMAIN_MANAGEMENT_ATTACH_DOMAIN_FAILED") {
					msg = $translate.instant(failure.message, { domain: $scope.domainPath });
				} else if (failure.message.contains("OS_ARCHIVE_PATH_CONVERSION|")) {
					vm.osPathConversionNeeded = true;
					vm.domainArchivePathOld = failure.message.substring("OS_ARCHIVE_PATH_CONVERSION|".length);
					vm.domainArchivePathNew = failure.message.substring("OS_ARCHIVE_PATH_CONVERSION|".length);
					return;
				} else if (failure.message === "DOMAIN_OS_ARCHIVE_PATH_NOT_VALID") {
					msg = $translate.instant("DOMAIN_OS_ARCHIVE_PATH_ERROR");
					vm.errorMessage = $translate.instant(failure.message, { operating_system: vm.os == 'L' ? "Linux" : "Windows" });
					time = 5000;
				} else if (failure.message === "DOMAIN_OS_ARCHIVE_PATH_NOT_FOUND") {
					msg = $translate.instant("DOMAIN_OS_ARCHIVE_PATH_ERROR");
					vm.errorMessage = $translate.instant(failure.message);
					time = 5000;
				} else if (failure.message === "DOMAIN_OS_ARCHIVE_PATH_INVALID_PERMISSIONS") {
					msg = $translate.instant("DOMAIN_OS_ARCHIVE_PATH_ERROR");
					vm.errorMessage = $translate.instant(failure.message);
					time = 5000;
				} else {
					failure.message.split("\n").filter(function (i) { return i; }).forEach(function (str) { msg += $translate.instant(str) + "\n"; });
				}
				msg = $sanitize(msg.trim());
				msg = msg.replace(new RegExp("&#10;", 'g'), "<br>");

				toaster.pop({
					type: 'error',
					title: null,
					body: msg,
					timeout: time,
					bodyOutputType: 'trustedHtml'
				});
			}
			else if (failure)
				toaster.pop("error", $translate.instant(failure));
			else
				toaster.pop("error", $translate.instant("ERROR_HAS_OCCURRED"));
		}
	}

})();